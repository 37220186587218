import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';
import { Session }    from '../models/session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private localStorageService;
  private currentSession : Session = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
   }
   
  getBasePath() {
    if (location.hostname == "192.168.50.51" || location.hostname == "intranet.gestimedica.int") {
      return "http://api-celsus.gestimedica.test/api/auth/";
      //return "http://dev.api-celsus.gestimedica.int/api/auth/";
    }else if (location.hostname == "precelsus.upsan.net") {
      return 'https://preapi.upsan.net/api/auth/';
    }
    else {
      return 'https://api.upsan.net/api/auth/';
    }
  }

  /*getBasePath() {
   return 'https://api.upsan.es/api/auth/'; 
  }*/

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }

  loadSessionData(): Session{
    var sessionStr = this.localStorageService.getItem('currentUser');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.currentSession = null;
  }

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.access_token) ? session.access_token : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  isExpiredToken(): boolean {
    var dateNow = new Date();
    var tokenExpired = new Date(this.currentSession.expires_at);
    
    if ( tokenExpired > dateNow )
      return false;

    return true;
  };

  logout(): void{
    this.removeCurrentSession();
    this.router.navigate(['']);
  }
}
